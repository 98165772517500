<template>
  <div class="account">
    <!-- register_annual_subheader -->
    <section id="sub_header_box" class="manual">
      <div class="w1300">

        <div class="sub_header_description txt-dark_gray">

          <div v-html="wordFromChairman"></div>

          <div class="info_spread" @click="toggleBottomInfo">
            <div class="top">
              <h4 class="txt-dark_gray txt-bold">特別感謝名單</h4>
              <div class="arrow" :class="{active: topActive}">
                <img src="@/statics/img/about/icon_arrow.svg" alt="">
              </div>
            </div>
            <VueSlideToggle class="bottom" :open="open" :duration="500">
              <div id="text_editor" v-html="specialThanksList"></div>
            </VueSlideToggle>
          </div>

          <img class="heart_bg" src="@/statics/img/index/icon_heart.svg" alt="">

        </div>
      </div>
    </section>

    <section id="manual">
      <div class="w1300 manual_container">

        <div class="manual_top">
          <div class="main_title_box">
            <h3 class="main_title txt-bold">醫師手冊</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
          </div>

          <div class="filter">
            <form action="" method="" @submit.prevent>
              <div class="time_filter">
                <!-- 透過關鍵字篩選 -->
                <div class="input-wrap">
                  <p class="small txt-bold txt-dark_gray">關鍵字搜尋</p>
                  <input
                    class="search"
                    type="text"
                    name="key"
                    v-model.trim="placeHolder"
                    @keyup.enter="updateKeyword"
                  >
                  <button type="submit" class="p" @click="updateKeyword">查詢</button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="card_box">
          <router-link
            :to="`/account/manual/pdf/${data.id}`"
            v-for="data in dataPerPage"
            :key="data.id"
            class="card_item"
          >
            <h4 class="card_title txt-bold">{{ data.title }}</h4>
            <ul class="card_list">
              <li
                class="small"
                v-for="info in data.infoList"
                :key="info.id"
              >
                {{ info.content }}
              </li>
            </ul>
            <div class="card_tag small">查看</div>
          </router-link>
        </div>

        <Pagination
          :pageData="dataByKeyword"
          :perPage="perPage"
          :nowPage="nowPage"
          :pageLength="pageLength"
          @updateNowPage="updateNowPage"
        />

      </div>
    </section>
  </div>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'AccountManualIndex',
  data() {
    return {
      open: false,
      topActive: false,
      wordFromChairman: `
        <p>居家醫療照護整合計畫目的之一是提供病人整合性之全人照護，居家患者因失能或疾病特殊導致外出就醫不便，相對的，
          醫師必須對於各項用藥要有一定程度了解，第一版醫師手冊統整了居家醫療中常見疾病及用藥，希望能幫助醫師為患者提供服務。
          <br>居家醫療醫師手冊由副理事長 楊昆澈醫師總編輯，在此，本醫學會感謝編制及審稿居家醫療醫師手冊之醫師群。
          <br>本次為第一次出版居家醫療醫師手冊，以後每三年內更改版本乙次，除已編之疾病將再一一增加新內容。
          <br>
          <br>理事長 郭啟昭 109.5.31
        </p>`,
      specialThanksList: `
        <p>
          編輯醫師:
          <br> 台大醫院 張哲瑞醫師
          <br> 台大醫院 蘇柏維醫師
          <br> 台大醫院 郭芳慈醫師
          <br> 台大醫院北護分院 李伊真醫師
          <br> 台大醫院北護分院 李怡萱醫師
          <br> 台大醫院北護分院 林怡妏醫師
          <br> 台大醫院北護分院 胡惠姝護理師
          <br> 台大醫院雲林分院 葉忻瑜醫師
          <br> 中國醫藥大學附設醫院 陳宗伯醫師
          <br>
          <br> 審稿醫師:
          <br> 台灣居家醫療醫學會 郭啓昭理事長
          <br> 台灣居家醫療醫學會 楊昆澈副理事長
          <br> 台灣居家醫療醫學會 傅華國秘書長
          <br> 台大醫院北護分院 林怡妏醫師
          <br> 台大醫院北護分院 蔡欣熹醫師
        </p>`,
      dataArray: [
        {
          id: 1,
          title: '糖尿病疾病介紹',
          infoList: [
            {
              id: 1,
              content: '台大醫院北護分院李伊真醫師編輯',
            },
            {
              id: 2,
              content: '台大醫院北護分院楊昆澈醫師初審',
            },
            {
              id: 3,
              content: '台灣居家醫療醫學會理事長郭啟昭醫師複審123',
            },
          ],
        },
        {
          id: 2,
          title: '測試測試測試',
          infoList: [
            {
              id: 1,
              content: '台大醫院北護分院李伊真醫師編輯',
            },
            {
              id: 2,
              content: '台大醫院北護分院楊昆澈醫師初審',
            },
            {
              id: 3,
              content: '台灣居家醫療醫學會理事長郭啟昭醫師複審',
            },
          ],
        },
        {
          id: 3,
          title: '糖尿病疾病介紹',
          infoList: [
            {
              id: 1,
              content: '台大醫院北護分院李伊真醫師編輯',
            },
            {
              id: 2,
              content: '台大醫院北護分院楊昆澈醫師初審',
            },
            {
              id: 3,
              content: '台灣居家醫療醫學會理事長郭啟昭醫師複審',
            },
          ],
        },
        {
          id: 4,
          title: '測試測試測試',
          infoList: [
            {
              id: 1,
              content: '台大醫院北護分院李伊真醫師編輯',
            },
            {
              id: 2,
              content: '台大醫院北護分院楊昆澈醫師初審',
            },
            {
              id: 3,
              content: '台灣居家醫療醫學會理事長郭啟昭醫師複審',
            },
          ],
        },
        {
          id: 5,
          title: '糖尿病疾病介紹',
          infoList: [
            {
              id: 1,
              content: '台大醫院北護分院李伊真醫師編輯',
            },
            {
              id: 2,
              content: '台大醫院北護分院楊昆澈醫師初審',
            },
            {
              id: 3,
              content: '台灣居家醫療醫學會理事長郭啟昭醫師複審',
            },
          ],
        },
        {
          id: 6,
          title: '測試測試測試',
          infoList: [
            {
              id: 1,
              content: '台大醫院北護分院李伊真醫師編輯',
            },
            {
              id: 2,
              content: '台大醫院北護分院楊昆澈醫師初審',
            },
            {
              id: 3,
              content: '台灣居家醫療醫學會理事長郭啟昭醫師複審',
            },
          ],
        },
        {
          id: 7,
          title: '糖尿病疾病介紹',
          infoList: [
            {
              id: 1,
              content: '台大醫院北護分院李伊真醫師編輯',
            },
            {
              id: 2,
              content: '台大醫院北護分院楊昆澈醫師初審',
            },
            {
              id: 3,
              content: '台灣居家醫療醫學會理事長郭啟昭醫師複審',
            },
          ],
        },
        {
          id: 8,
          title: '測試測試測試',
          infoList: [
            {
              id: 1,
              content: '台大醫院北護分院李伊真醫師編輯',
            },
            {
              id: 2,
              content: '台大醫院北護分院楊昆澈醫師初審',
            },
            {
              id: 3,
              content: '台灣居家醫療醫學會理事長郭啟昭醫師複審',
            },
          ],
        },
        {
          id: 9,
          title: '糖尿病疾病介紹',
          infoList: [
            {
              id: 1,
              content: '台大醫院北護分院李伊真醫師編輯',
            },
            {
              id: 2,
              content: '台大醫院北護分院楊昆澈醫師初審',
            },
            {
              id: 3,
              content: '台灣居家醫療醫學會理事長郭啟昭醫師複審',
            },
          ],
        },
        {
          id: 10,
          title: '測試測試測試',
          infoList: [
            {
              id: 1,
              content: '台大醫院北護分院李伊真醫師編輯',
            },
            {
              id: 2,
              content: '台大醫院北護分院楊昆澈醫師初審',
            },
            {
              id: 3,
              content: '台灣居家醫療醫學會理事長郭啟昭醫師複審',
            },
          ],
        },
      ],

      // Pagination
      placeHolder: '',
      keyword: '',
      perPage: 8,
      nowPage: 1,
      pageLength: 5,
    };
  },
  computed: {
    // Pagination
    dataByKeyword() {
      if (this.keyword) {
        return this.dataArray.filter((data) => {
          const testInfo = [];
          for (let i = 0; i < data.infoList.length; i += 1) {
            if (data.infoList[i].content.includes(this.keyword)) {
              testInfo.push(data.infoList[i]);
            }
          }
          return data.title.includes(this.keyword)
          || testInfo.length > 0;
        });
      }
      return this.dataArray;
    },
    dataPerPage() {
      const start = (this.nowPage - 1) * this.perPage;
      if (this.dataByKeyword.length < this.perPage) {
        return this.dataByKeyword;
      }
      return this.dataByKeyword.slice(start, start + this.perPage);
    },
  },
  methods: {
    toggleBottomInfo() {
      this.topActive = !this.topActive;
      this.open = !this.open;
    },
    // Pagination
    updateKeyword() {
      this.keyword = this.placeHolder;
    },
    updateNowPage(value) {
      this.nowPage = value;
    },
  },
  components: {
    VueSlideToggle,
    Pagination,
  },
};
</script>
